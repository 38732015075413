import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxInfo from 'components/Advanced_User/All_ioBroker_Tutorials/PrimaryBox';
import PrimaryBox from 'components/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/PrimaryBox';
import TimeLine from 'components/Products/CommentList/MqttIFTTTList';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Using MQTT to inform ioBroker that an Alarm was Triggered",
  "path": "/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/",
  "dateChanged": "2020-04-07",
  "author": "Mike Polinowski",
  "excerpt": "I found your guide to use the INSTAR (HTTP) Alarmserver. Wouldn't it make more sense to publish those events through the MQTT service that every Full HD cameras offers? This way I would be able to be sure that every alarm event is registered by my ioBroker installation.",
  "image": "./FAQ_SearchThumb.png",
  "social": "/images/Search/FAQ_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_FAQs_white.webp",
  "chapter": "FAQs",
  "faq": "Full HD Cameras",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='FAQs - Using MQTT to inform ioBroker that an Alarm was Triggered' dateChanged='2020-04-07' author='Mike Polinowski' tag='INSTAR IP Camera' description='I found your guide to use the INSTAR (HTTP) Alarmserver. Wouldn`t it make more sense to publish those events through the MQTT service that every Full HD cameras offers? This way I would be able to be sure that every alarm event is registered by my ioBroker installation.' image='/images/Search/FAQ_SearchThumb.png' twitter='/images/Search/FAQ_SearchThumb.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/' locationFR='/fr/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_ioBroker/' crumbLabel="FAQs" mdxType="BreadCrumbs" />
    <PrimaryBoxInfo mdxType="PrimaryBoxInfo" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h1 {...{
      "id": "using-mqtt-to-inform-iobroker-that-an-alarm-was-triggered",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-mqtt-to-inform-iobroker-that-an-alarm-was-triggered",
        "aria-label": "using mqtt to inform iobroker that an alarm was triggered permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using MQTT to inform ioBroker that an Alarm was Triggered`}</h1>
    <p><strong parentName="p">{`Q`}</strong>{`: I found your `}<a parentName="p" {...{
        "href": "/en/Advanced_User/Alarmserver_Queries_for_your_Smarthome/"
      }}>{`guide to use the INSTAR (HTTP) Alarmserver`}</a>{`. Wouldn't it make more sense to publish those events through the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`MQTT service that every Full HD cameras`}</a>{` offers? This way I would be able to be sure that every alarm event is registered by my ioBroker installation.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <TimeLine mdxType="TimeLine" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><strong parentName="p">{`A`}</strong>{`: All alarm events that published by the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Alarm/Alarm_Server/"
      }}>{`INSTAR HTTP Alarmserver`}</a>{` can now also be accessed through the MQTT Service!`}</p>
    <h2 {...{
      "id": "mqtt-alarmserver",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mqtt-alarmserver",
        "aria-label": "mqtt alarmserver permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`MQTT Alarmserver`}</h2>
    <p>{`The corresponding `}<a parentName="p" {...{
        "href": "/en/Advanced_User/INSTAR_MQTT_Broker/MQTT_API/"
      }}>{`MQTT Topic`}</a>{` is:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}>{`alarm/triggered`}</code></pre></div>
    <p>{`All you need to do is adding a subscription to the `}<a parentName="p" {...{
        "href": "/en/Web_User_Interface/1080p_Series/Network/MQTT/"
      }}>{`Status Topic`}</a>{` and you will receive updates about alarm events as well as what service triggered the alarm:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "setting-up-iobroker",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#setting-up-iobroker",
        "aria-label": "setting up iobroker permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Setting up ioBroker`}</h2>
    <p>{`We already published a guide on how to use `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver/"
      }}>{`Node-RED to interact with your MQTT Alarmserver`}</a>{`. You can follow this guide if you installed the Node-RED adapter in ioBroker.`}</p>
    <p>{`In this guide we want to use the `}<strong parentName="p">{`Blockly`}</strong>{` Script Engine instead of Node-RED. You can start by installing it from the `}<strong parentName="p">{`Adapter`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/294fd4eb294f711be8004f08b1717b2d/1ac29/INSTAR_MQTT_Alarmserver_ioBroker_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAz0lEQVQY05WOzWrCQBhF84A+Uldd+w6u6sYKbemuuDIUasSMmEasyUwm/iTBzHfnpy4EwaJFdKmHs7wHrtf+FI/vyXPkOhMn1BbWUFVtBl/2Z+rSOa2WBO0MBpxaQd0dq7KG0fjHe3jyG82Pfnl4EYe3oTJVSdrAWDoJfRwSsDXY/2LncA5PcTSX/XAWJwt/VvRefSs4WQulQHT0jAJqgiJc4xXrlcw4T5JFJopyffl0A16WySAYMsai6DtNudb3xFLm4SgcMxbH0zzP72nxB9HyRDfpGmx1AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/294fd4eb294f711be8004f08b1717b2d/e4706/INSTAR_MQTT_Alarmserver_ioBroker_01.avif 230w", "/en/static/294fd4eb294f711be8004f08b1717b2d/d1af7/INSTAR_MQTT_Alarmserver_ioBroker_01.avif 460w", "/en/static/294fd4eb294f711be8004f08b1717b2d/7f308/INSTAR_MQTT_Alarmserver_ioBroker_01.avif 920w", "/en/static/294fd4eb294f711be8004f08b1717b2d/5dd4b/INSTAR_MQTT_Alarmserver_ioBroker_01.avif 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/294fd4eb294f711be8004f08b1717b2d/a0b58/INSTAR_MQTT_Alarmserver_ioBroker_01.webp 230w", "/en/static/294fd4eb294f711be8004f08b1717b2d/bc10c/INSTAR_MQTT_Alarmserver_ioBroker_01.webp 460w", "/en/static/294fd4eb294f711be8004f08b1717b2d/966d8/INSTAR_MQTT_Alarmserver_ioBroker_01.webp 920w", "/en/static/294fd4eb294f711be8004f08b1717b2d/6c19f/INSTAR_MQTT_Alarmserver_ioBroker_01.webp 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/294fd4eb294f711be8004f08b1717b2d/81c8e/INSTAR_MQTT_Alarmserver_ioBroker_01.png 230w", "/en/static/294fd4eb294f711be8004f08b1717b2d/08a84/INSTAR_MQTT_Alarmserver_ioBroker_01.png 460w", "/en/static/294fd4eb294f711be8004f08b1717b2d/c0255/INSTAR_MQTT_Alarmserver_ioBroker_01.png 920w", "/en/static/294fd4eb294f711be8004f08b1717b2d/1ac29/INSTAR_MQTT_Alarmserver_ioBroker_01.png 1022w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/294fd4eb294f711be8004f08b1717b2d/c0255/INSTAR_MQTT_Alarmserver_ioBroker_01.png",
              "alt": "INSTAR MQTT Alarmserver for the ioBroker",
              "title": "INSTAR MQTT Alarmserver for the ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now switch to the `}<strong parentName="p">{`Scripts`}</strong>{` tab and select `}<strong parentName="p">{`Blockly`}</strong>{` to add a new script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ec287d0bdfd5d5ea207629247109a681/d0143/INSTAR_MQTT_Alarmserver_ioBroker_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "15.217391304347828%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAIAAAAcOLh5AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAAg0lEQVQI132LwQrCMBAF8/8/IzSg4qVFvHuy9RKjREipYOluNjHLBkTj2eEdHgOjYre3w3nV6LbtnHMi8kq0OxyNGzknDIGIYoxE9HmhQlWq8XY3xupGrzfbU99Pj8l7f7FXxICIAEBfagQAy7L8aiIlpZQidSwMAVJM8/xkzvyXnPMbYoinNJ1Km04AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec287d0bdfd5d5ea207629247109a681/e4706/INSTAR_MQTT_Alarmserver_ioBroker_02.avif 230w", "/en/static/ec287d0bdfd5d5ea207629247109a681/d1af7/INSTAR_MQTT_Alarmserver_ioBroker_02.avif 460w", "/en/static/ec287d0bdfd5d5ea207629247109a681/7f308/INSTAR_MQTT_Alarmserver_ioBroker_02.avif 920w", "/en/static/ec287d0bdfd5d5ea207629247109a681/b1bd9/INSTAR_MQTT_Alarmserver_ioBroker_02.avif 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ec287d0bdfd5d5ea207629247109a681/a0b58/INSTAR_MQTT_Alarmserver_ioBroker_02.webp 230w", "/en/static/ec287d0bdfd5d5ea207629247109a681/bc10c/INSTAR_MQTT_Alarmserver_ioBroker_02.webp 460w", "/en/static/ec287d0bdfd5d5ea207629247109a681/966d8/INSTAR_MQTT_Alarmserver_ioBroker_02.webp 920w", "/en/static/ec287d0bdfd5d5ea207629247109a681/d1bdf/INSTAR_MQTT_Alarmserver_ioBroker_02.webp 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ec287d0bdfd5d5ea207629247109a681/81c8e/INSTAR_MQTT_Alarmserver_ioBroker_02.png 230w", "/en/static/ec287d0bdfd5d5ea207629247109a681/08a84/INSTAR_MQTT_Alarmserver_ioBroker_02.png 460w", "/en/static/ec287d0bdfd5d5ea207629247109a681/c0255/INSTAR_MQTT_Alarmserver_ioBroker_02.png 920w", "/en/static/ec287d0bdfd5d5ea207629247109a681/d0143/INSTAR_MQTT_Alarmserver_ioBroker_02.png 1025w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ec287d0bdfd5d5ea207629247109a681/c0255/INSTAR_MQTT_Alarmserver_ioBroker_02.png",
              "alt": "INSTAR MQTT Alarmserver for the ioBroker",
              "title": "INSTAR MQTT Alarmserver for the ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can now start using the Blockly UI to create your alarm server script:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/eb3fa/INSTAR_MQTT_Alarmserver_ioBroker_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "27.82608695652174%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABDElEQVQY032OTU/CQBiE+689evQiBw9evCLogYSEoMaA25A0KQS/02IVU0NDWVs+2m27y77b3cWANxN8DpOZw2TGECAwxnE8BxBSSspYkWf1tjWLlqskWyb5mvP1Ds652KFlCeWGQ2mkhHxPJzEOaZ6ygtA8zZL529gPwiiKZqtFyGkKAEIIQkgQBBjj5/cAf5mUhEar99QyHxq3g2Z32OjYF9dWrW31rHvXdYKJj8MJcKq1VkoBQFEUjLFFkrNsKktqVKqd42q3cn5XqaGjs6vD0+bBSeOyfmNb1qvjfIw/AWCzB+NP1nqrztBByLTtvud5Qoi9ZbW9pLf6a5SSUr08DhBC7mjk+/4/yz/McUcWXWSMNwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a4207ec4363398c4a3bd5f9be67a11b/e4706/INSTAR_MQTT_Alarmserver_ioBroker_03.avif 230w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/d1af7/INSTAR_MQTT_Alarmserver_ioBroker_03.avif 460w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/7f308/INSTAR_MQTT_Alarmserver_ioBroker_03.avif 920w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/b248f/INSTAR_MQTT_Alarmserver_ioBroker_03.avif 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/6a4207ec4363398c4a3bd5f9be67a11b/a0b58/INSTAR_MQTT_Alarmserver_ioBroker_03.webp 230w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/bc10c/INSTAR_MQTT_Alarmserver_ioBroker_03.webp 460w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/966d8/INSTAR_MQTT_Alarmserver_ioBroker_03.webp 920w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/1dc52/INSTAR_MQTT_Alarmserver_ioBroker_03.webp 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/6a4207ec4363398c4a3bd5f9be67a11b/81c8e/INSTAR_MQTT_Alarmserver_ioBroker_03.png 230w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/08a84/INSTAR_MQTT_Alarmserver_ioBroker_03.png 460w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/c0255/INSTAR_MQTT_Alarmserver_ioBroker_03.png 920w", "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/eb3fa/INSTAR_MQTT_Alarmserver_ioBroker_03.png 1026w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/6a4207ec4363398c4a3bd5f9be67a11b/c0255/INSTAR_MQTT_Alarmserver_ioBroker_03.png",
              "alt": "INSTAR MQTT Alarmserver for the ioBroker",
              "title": "INSTAR MQTT Alarmserver for the ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`In the following example I re-created the `}<a parentName="p" {...{
        "href": "/en/Frequently_Asked_Question/INSTAR_MQTT_Alarmserver_Motion_Tracking/"
      }}>{`Motion Tracking Example that we previously solved in Node-RED`}</a>{`. Please refer to this turorials for details on how to set up your camera for this to work.`}</p>
    <p>{`Whenever we receive a status update for `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`alarm/triggered`}</code>{`, we have to check if the alarm was triggered by `}<strong parentName="p">{`Area 1`}</strong>{` - payload will be `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"1"}`}</code>{` - or `}<strong parentName="p">{`Area 4`}</strong>{` - with the payload `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"4"}`}</code>{`. If `}<strong parentName="p">{`Area 1`}</strong>{` was triggered - which is the motion detection area we added to the left side - we have to move our camera one step to the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`left`}</code>{`. For `}<strong parentName="p">{`Area 4`}</strong>{` we have to go `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`right`}</code>{` to center the moving object in our cameras field of view.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3dded634b4a620808da3aee1ef31a273/75609/INSTAR_MQTT_Alarmserver_ioBroker_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAAB00lEQVQY01WQy0/acACA+3fusmzJ7jNxGQnqzSwM5ogbyzTqCOKkgFUiU5ijaB8/aEsppSuv0toHKzoQ+xAkktnN7bTv8N2+ywedFXGGYVRNlRsKHUd5OE9vZuWO0R+5eu9S612oxqWsm7Jmynr/bjbzvPt/eJ4HkQUClECj3WyUartzq5/9kdSLVY2T3LvZ0LJGrmPfjD3v3vN+/fV/QOAUkIAU280mYBcWdp4FEf/ydiSc8n888EV3F2NwIHnMtXuUqBOiVJLaWF3CawrJK+54ClUqVJ2vaz2jSbJzvujjIBLaONqK5cOJwtt0LpQ4XM8UAdfBWOkb38BbIsq1sKpM8MrNZAqdAoKmaEVXGyT70h97GkQC75Bo9EtoJ/caPniT2H+//7XMSzjXLTzE34v1FsYpKCuZQxtCcYKmqO650gTVed/2kyAS+JCJxU/CMLqyl1tJH61lzyqiVhZUXOjSXYkQJYI/x2ryz2sXIjFcEAT9hyHizPP5jUfLsD8IhyPI0lpm8VNqaTP5Kn54TNSTaHWvUkoDJo0KcIGTjMHDMAoDZAl0FVkVO/kkmssCoSwOrmx3MnWcW8e5tZzJxZVtDi1zdN0fWf2hbQ4sdzz9E/8G72F5nza1cysAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dded634b4a620808da3aee1ef31a273/e4706/INSTAR_MQTT_Alarmserver_ioBroker_04.avif 230w", "/en/static/3dded634b4a620808da3aee1ef31a273/d1af7/INSTAR_MQTT_Alarmserver_ioBroker_04.avif 460w", "/en/static/3dded634b4a620808da3aee1ef31a273/7f308/INSTAR_MQTT_Alarmserver_ioBroker_04.avif 920w", "/en/static/3dded634b4a620808da3aee1ef31a273/602a0/INSTAR_MQTT_Alarmserver_ioBroker_04.avif 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3dded634b4a620808da3aee1ef31a273/a0b58/INSTAR_MQTT_Alarmserver_ioBroker_04.webp 230w", "/en/static/3dded634b4a620808da3aee1ef31a273/bc10c/INSTAR_MQTT_Alarmserver_ioBroker_04.webp 460w", "/en/static/3dded634b4a620808da3aee1ef31a273/966d8/INSTAR_MQTT_Alarmserver_ioBroker_04.webp 920w", "/en/static/3dded634b4a620808da3aee1ef31a273/fd213/INSTAR_MQTT_Alarmserver_ioBroker_04.webp 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3dded634b4a620808da3aee1ef31a273/81c8e/INSTAR_MQTT_Alarmserver_ioBroker_04.png 230w", "/en/static/3dded634b4a620808da3aee1ef31a273/08a84/INSTAR_MQTT_Alarmserver_ioBroker_04.png 460w", "/en/static/3dded634b4a620808da3aee1ef31a273/c0255/INSTAR_MQTT_Alarmserver_ioBroker_04.png 920w", "/en/static/3dded634b4a620808da3aee1ef31a273/75609/INSTAR_MQTT_Alarmserver_ioBroker_04.png 994w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3dded634b4a620808da3aee1ef31a273/c0255/INSTAR_MQTT_Alarmserver_ioBroker_04.png",
              "alt": "INSTAR MQTT Alarmserver for the ioBroker",
              "title": "INSTAR MQTT Alarmserver for the ioBroker",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <blockquote>
      <p parentName="blockquote">{`Note that the camera that I am using here has the MQTT ID `}<code parentName="p" {...{
          "className": "language-text"
        }}>{`10D1DC21F5DB`}</code>{`. If you import the Blockly script you have to changes this according to your personal cameras ID!`}</p>
    </blockquote>
    <p>{`The topics that are used here are:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/status/alarm/triggered`}</code>{`: INSTAR MQTT Alarmserver Status Topic`}</li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/features/ptz/movestep/raw`}</code>{`: Command topic to move your pan&tilt camera one step to the `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`left`}</code>{` or `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`right`}</code>{`.`}</li>
    </ul>
    <p>{`And in addition I added a script that will move my camera to preset position 2, if the camera's `}<strong parentName="p">{`Audio Alarm`}</strong>{` `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{"val":"6"}`}</code>{` was triggered. This can be done with the following:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/features/ptz/presets/raw`}</code>{` Command topic to move your camera to position `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`0`}</code>{` - `}<code parentName="li" {...{
          "className": "language-text"
        }}>{`7`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`/alarm/pushalarm`}</code>{` Command topic to trigger an audio alarm on your camera and trigger the preset function in Blockly`}</li>
    </ul>
    <h2 {...{
      "id": "xml-export",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#xml-export",
        "aria-label": "xml export permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`XML Export`}</h2>
    <p>{`You can import the complete script into Blockly with the following JSON code. Note that you will have to replace the MQTT ID `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`10D1DC21F5DB`}</code>{` with your personal cameras ID:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}>{`<xml xmlns=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"http://www.w3.org/1999/xhtml"`}</span>{`>
  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_ext"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"5:E8z%?$kF2:_228=Fsl"`}</span>{` x=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-62"`}</span>{` y=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-112"`}</span>{`>
    <mutation items=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span>{`></mutation>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"CONDITION"`}</span>{`>ne</field>
    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ACK_CONDITION"`}</span>{`></field>
    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID0"`}</span>{`>
      <shadow type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"field_oid"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"7K},E#*On\`zsq^jhPQ9v"`}</span>{`>
        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"oid"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.10D1DC21F5DB.status.alarm.triggered</field>
      </shadow>
    </value>
    <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"STATEMENT"`}</span>{`>
      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"controls_if"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"2%z$7WDJUl-dSz+miU(-"`}</span>{`>
        <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"IF0"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"logic_compare"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"9]pjLD{[W^26TPYM6liS"`}</span>{`>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OP"`}</span>{`>EQ</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"A"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_source"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"-?/H,NwcYjPh~{p\`fc\`~"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ATTR"`}</span>{`>state.val</field>
              </block>
            </value>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"B"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"hKDWmg?WzR9@$Wo4RHj5"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"1"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`</field>
              </block>
            </value>
          </block>
        </value>
        <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DO0"`}</span>{`>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"g@M?e*b1_8ezO{_(PUcP"`}</span>{`>
            <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.10D1DC21F5DB.features.ptz.movestep.raw</field>
            <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"u2H{{k$7nO+({(J4,2,C"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>left</field>
              </block>
            </value>
          </block>
        </statement>
        <next>
          <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"controls_if"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"}uq*%|F/Nt=*\`rn\`|hV4"`}</span>{`>
            <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"IF0"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"logic_compare"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"|x2u0AR*aTmVC=lfROzF"`}</span>{`>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OP"`}</span>{`>EQ</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"A"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_source"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"In|(S^y0YVoVN#Y^[.5m"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ATTR"`}</span>{`>state.val</field>
                  </block>
                </value>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"B"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"uC/+7LgdO]A\`UdHW*sHC"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"4"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`</field>
                  </block>
                </value>
              </block>
            </value>
            <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DO0"`}</span>{`>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"K58]j^_|1M4|}D2aF?{u"`}</span>{`>
                <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.10D1DC21F5DB.features.ptz.movestep.raw</field>
                <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"13|%(\`^fM,_w499|gkt-"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>right</field>
                  </block>
                </value>
              </block>
            </statement>
            <next>
              <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"controls_if"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"JhXZ,A)R?fQ}!et!,!/7"`}</span>{`>
                <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"IF0"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"logic_compare"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"560g5G|oV?rEcXER(oOD"`}</span>{`>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OP"`}</span>{`>EQ</field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"A"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"on_source"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"9[A80k}o*RpbflDD2h/:"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"ATTR"`}</span>{`>state.val</field>
                      </block>
                    </value>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"B"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"._5?cn:Mi%DWgz;OL}v4"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span><span parentName="code" {...{
            "className": "token property"
          }}>{`"val"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`"6"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`</field>
                      </block>
                    </value>
                  </block>
                </value>
                <statement name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"DO0"`}</span>{`>
                  <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"control"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"]KIbdkEE?:DEhmZ\`)wE="`}</span>{`>
                    <mutation delay_input=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"false"`}</span>{`></mutation>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"OID"`}</span>{`>mqtt.`}<span parentName="code" {...{
            "className": "token number"
          }}>{`0`}</span>{`.instar.10D1DC21F5DB.features.ptz.preset.raw</field>
                    <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"WITH_DELAY"`}</span>{`>FALSE</field>
                    <value name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"VALUE"`}</span>{`>
                      <block type=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"text"`}</span>{` id=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"1HbkwoM-U/]J2go|iNoe"`}</span>{`>
                        <field name=`}<span parentName="code" {...{
            "className": "token string"
          }}>{`"TEXT"`}</span>{`>`}<span parentName="code" {...{
            "className": "token number"
          }}>{`2`}</span>{`</field>
                      </block>
                    </value>
                  </block>
                </statement>
              </block>
            </next>
          </block>
        </next>
      </block>
    </statement>
  </block>
</xml>`}</code></pre></div>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/en/FAQs/"
        }}>{`FAQs`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9420WQHD/"
            }}>{`IN-9420 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9408WQHD/"
            }}>{`IN-9408 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8415WQHD/"
            }}>{`IN-8415 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8403WQHD/"
            }}>{`IN-8403 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8401WQHD/"
            }}>{`IN-8401 WQHD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9020FHD/"
            }}>{`IN-9020 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9010FHD/"
            }}>{`IN-9010 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-9008FHD/"
            }}>{`IN-9008 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8015FHD/"
            }}>{`IN-8015 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8003FHD/"
            }}>{`IN-8003 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-8001FHD/"
            }}>{`IN-8001 Full HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-7011HD/"
            }}>{`IN-7011 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6014HD/"
            }}>{`IN-6014 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6012HD/"
            }}>{`IN-6012 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-6001HD/"
            }}>{`IN-6001 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5907HD/"
            }}>{`IN-5907 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/IN-5905HD/"
            }}>{`IN-5905 HD`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "/en/FAQs/VGA_Series/"
            }}>{`VGA Series`}</a></li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      